import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../stylesheets/privacy.scss"

const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO
      title="個人情報保護方針"
      description="道新インタラクティブ株式会社の個人情報保護方針について掲載しています。"
    />
    <section className="privacyPage-MainSection">
      {data.allMicrocmsPrivacy.edges.map(({ node }) => (
        <div
          key={node.id}
          className="privacyPage-MainSection-inner"
          dangerouslySetInnerHTML={{
            __html: `${node.body}`,
          }}
        />
      ))}
    </section>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query {
    allMicrocmsPrivacy {
      edges {
        node {
          id
          body
        }
      }
    }
  }
`
